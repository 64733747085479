import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';

import { PhxToastButton, PhxToastType } from '../components/phx-toast/phx-toast-types';
import { PhxToastComponent } from '../components/phx-toast/phx-toast.component';

/**
 * Encapsulates the phx-toast management functionalities
 */
@Injectable()
export class PhxToastService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  /**
   * Displays the toast with head, message and with a style
   * @param header Head text of the toast
   * @param messageBody Message body
   * @param type Type of the toast
   * @param optionButtons Toast buttons configuration list
   * @param duration Duration of toast, or negative to keep the toast open
   */
  public showToast(header: string | null, messageBody: string, type: PhxToastType, optionButtons: PhxToastButton[] = [], duration?: number): PhxToastComponent {
    // Create a component reference from the component
    const componentRef = this.resolveComponentRef();

    const toastComponent = componentRef.instance as PhxToastComponent;
    if (duration > 0) {
      toastComponent.displayDuration = duration;
    }
    toastComponent.onCloseFn = (phxToastComponent: PhxToastComponent) => this.discardToast(phxToastComponent);
    toastComponent.componentRef = componentRef;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    // Append DOM element to the body
    const container = document.getElementsByClassName('phx-toast-container')[0] as HTMLDivElement;
    container.appendChild(domElem);
    toastComponent.showToast(header, messageBody, type, optionButtons);

    return toastComponent;
  }

  /**
   * Resolves the component reference
   * @param component Component object
   */
  private resolveComponentRef(component: any = null) {
    return component ? this.componentFactoryResolver
        .resolveComponentFactory(component).create(this.injector) :
      this.componentFactoryResolver
        .resolveComponentFactory(PhxToastComponent).create(this.injector);
  }

  /**
   * Removes the toast component from the DOM
   * @param component toast component
   */
  private discardToast(component: PhxToastComponent) {
    const componentRef = component.componentRef;
    if (!componentRef.hostView.destroyed) {
      componentRef.destroy();
    }
  }
}
