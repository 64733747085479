import { Injectable } from '@angular/core';

function currentWindow(): any {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowRefService {
    get nativeWindow(): any {
        return currentWindow();
    }

    openUrl(url: string, target: string = '_blank') {
        return this.nativeWindow.open(url, target);
    }
}
