import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { ApiService } from '@common';
import { FeatureFlag } from '@common/model/gobal-configuration/feature-flag';
import { GlobalConfiguration } from '@common/model/gobal-configuration/global-configuration';
import { AuthService } from '@common/services/auth.service';
import { environment } from '@environment';

import { IConfigurationRequest, IConfigurationResponse } from '../models';
import { FeatureFlagState } from '../models/feature-is-active';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private featureConfiguration$ = new BehaviorSubject<FeatureFlag[]>([]);
  private featureConfigurationData$ = new BehaviorSubject<GlobalConfiguration<FeatureFlag>[]>([]);

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.subscribeProfileChanges(() => this.fetchFeatureConfigurationData());
  }

  getConfiguration(
    configurationRequest: IConfigurationRequest
  ) {
    return this.apiService.httpGetRequest<IConfigurationResponse>(
      `Configuration?BusinessConfigurationCode=${configurationRequest.businessConfigurationCode}
        &TenantName=${configurationRequest.TenantName}&InternalCompanyCode=${configurationRequest.internalCompanyCode}
        &BranchCode=${configurationRequest.branchCode}&LOB=${configurationRequest.lineOfBusiness}&Country=${configurationRequest.country}
        &ProvinceStateCode=${configurationRequest.provinceStateCode}&ClientCode=${configurationRequest.clientCode}
        &Variable1=${configurationRequest.variable1}&Variable2=${configurationRequest.variable2}`,
      environment.adminServiceApiEndpoint,
      false);
  }

  /** NOTE: we load feature configuration data when the app initializes - app.resolver.ts, external-app.resolver.ts */
  fetchFeatureConfigurationData(): Promise<FeatureFlag[]> {
    return new Promise((resolve, reject) => {
      if (!this.authService.isLoggedIn()) {
        return reject();
      }

      this.getFeatureFlagConfigurationData$().subscribe({
        next:
          (configFeatureFlags: FeatureFlag[]) => {
            this.featureConfiguration$.next(configFeatureFlags);
            if (!configFeatureFlags?.length) {
              resolve([]);
            } else {
              resolve(configFeatureFlags);
            }
          },
        error: () => {
          this.featureConfiguration$.next([]);
          resolve([]);
        }
      });
    });
  }

  /** NOTE: used in access guard for routing by role */
  getFeatureFlagConfigurationData$(): Observable<FeatureFlag[]> {
    return this.featureConfigurationData$.pipe(
      take(1),
      switchMap(featureConfigurationData => !!featureConfigurationData.length
        ? of(featureConfigurationData)
        : this.http.get(environment.adminServiceApiEndpoint + '/globalConfiguration/FeatureFlag/FeatureConfiguration')
      ),
      switchMap((featureConfigurationData: GlobalConfiguration<FeatureFlag>[]) => {
        this.featureConfigurationData$.next(featureConfigurationData);
        const flags = featureConfigurationData.map(m => m.Data);
        this.featureConfiguration$.next(flags);
        return of(flags);
      }),
      catchError(() => of([]))
    );
  }

  /** NOTE: feature flag list - PhxConstants.FeatureFlags */
  /** NOTE: if in a service - always get feature flags data from this observable */
  isFeatureActive$(features: string[]): Observable<FeatureFlagState>;
  isFeatureActive$(features: string): Observable<boolean>;
  isFeatureActive$(features: string | string[]): Observable<FeatureFlagState | boolean> {
    return this.featureConfiguration$.pipe(
      filter(featureConfiguration => !!featureConfiguration),
      map(featureConfiguration => {

        const checkISFeatureEnabled = (feature: string) => featureConfiguration.find(f => f.FeatureName === feature)?.Flag || false;

        if (Array.isArray(features)) {
          const response: FeatureFlagState = {};
          features.forEach(feature => {
            response[feature] = checkISFeatureEnabled(feature);
          });
          return response;
        } else {
          return checkISFeatureEnabled(features);
        }
      })
    );
  }

  /** NOTE: feature flag list - PhxConstants.FeatureFlags */
  /** NOTE: if in a component - can use this for feature flag data or the above observable */
  isFeatureActive(feature: string): boolean {
    return this.featureConfiguration$.value.find(f => f.FeatureName === feature)?.Flag ?? false;
  }
}
