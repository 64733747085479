import { Injectable } from '@angular/core';

import { GlobalConfigurationDto } from './models/global-configuration-dto';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminDataService {
  private readonly apiEndPoint = environment.adminServiceApiEndpoint;

  constructor(private apiService: ApiService) { }

  /** NOTE: get global configuration by current tenant*/
  public getGlobalConfiguration<TData>(documentType: string, groupName: string) {
    const url = `GlobalConfiguration/${documentType}/${groupName}`;

    return this.apiService.httpGetRequest<GlobalConfigurationDto<TData>[]>(url, this.apiEndPoint);
  }

  /** NOTE: get global configuration not specific to tenant  */
  public getUniversalGlobalConfiguration<TData>(documentType: string, groupName: string) {
    const url = `GlobalConfiguration/Universal/${documentType}/${groupName}`;

    return this.apiService.httpGetRequest<GlobalConfigurationDto<TData>[]>(url, this.apiEndPoint);
  }

  public updateGlobalConfiguration<TData>(documentType: string, groupName: string, data: GlobalConfigurationDto<TData>) {
    const url = `GlobalConfiguration/${documentType}/${groupName}`;

    return this.apiService.httpPutRequest<GlobalConfigurationDto<TData>>(url, data, this.apiEndPoint);
  }

  public addGlobalConfiguration<TData>(documentType: string, groupName: string, data: GlobalConfigurationDto<TData>) {
    const url = `GlobalConfiguration/${documentType}/${groupName}`;

    return this.apiService.httpPostRequest<GlobalConfigurationDto<TData>>(url, data, this.apiEndPoint);
  }
}
