import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ComponentRef, Input } from '@angular/core';

import { PhxToastButton, PhxToastPosition, PhxToastType } from './phx-toast-types';

/**
 * Encapsulates phx-toast interaction logic
 */
@Component({
  selector: 'phx-toast',
  templateUrl: './phx-toast.component.html',
  styleUrls: ['./phx-toast.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('fading', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('.5s', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('.5s', style({ opacity: 0 }))
    ])
  ])]
})
export class PhxToastComponent {

  /**
   * Specifies the toast position on the screen
   */
  @Input() position: PhxToastPosition = PhxToastPosition.Bottom;

  /**
   * Specifies the toast display duration
   */
  @Input() displayDuration = 5000;

  /**
   * Toast option buttons configuration
   */
  @Input() optionButtons: PhxToastButton[] = [];

  /**
   * Triggers on closing an toast (For internal use)
   */
  onCloseFn: (component: PhxToastComponent) => void;

  /**
   * Message header text
   */
  head: string;

  /**
   * Message body
   */
  messageBody: string;

  /**
   * Toast style
   */
  style: PhxToastType;

  /**
   * Component reference to dismiss the toast on clicking the close button
   */
  componentRef: ComponentRef<any>;

  /**
   * Types of a toast
   */
  phxToastTypes: typeof PhxToastType = PhxToastType;

  private closeTimeout: any;

  /**
   * Executes the button action
   * @param button Button of the action
   */
  onOptionButtonClick(button: PhxToastButton) {
    if (button) {
      button.onClick();
      this.closeToast(); // By default closes the toast
    }
  }

  /**
   * Closes the toast
   */
  public closeToast() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
    this.onCloseFn(this); // Telling the phx toast service to dismiss this toast
  }


  /**
   * Displays the toast with head, message and with a style
   * @param header Head text of the toast
   * @param messageBody Message body
   * @param type Type of the toast
   * @param optionButtons Toast buttons configuration list
   */
  public showToast(header: string | null, messageBody: string, type: PhxToastType, optionButtons: PhxToastButton[] = []) {
    this.head = header?.trim() ?? '';
    this.messageBody = messageBody.trim();
    this.style = type;
    this.optionButtons = optionButtons;
    this.componentRef.changeDetectorRef.detectChanges();
    if (this.displayDuration > 0) {
      setTimeout(() => {
        this.onCloseFn(this);
      }, this.displayDuration);
    }
  }
}

